<template>
  <div class="orderlist">
    <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="listcont">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="top">
            <div class="text">购买订单：</div>
            <div class="value">
              <div class="txt">{{ item.order_no }}</div><span class="copy" :data-clipboard-text="item.order_no"
                @click="copyText()"><img src="@/assets/img/de_icon6.png" alt=""></span>
            </div>
            <div class="status">{{ item.status_text }}</div>
          </div>

          <div class="equipbox">
            <div class="infobox">
              <div class="pic"><img :src="item.img" alt=""></div>
              <div class="cont">
                <div class="title"><span>{{ item.title }}</span>x{{ item.num }}</div>
                <div class="pricetext">
                  <div class="price">商品总价：￥{{ Math.round((item.price - item.freight) * 100) / 100 }}</div>
                  <div class="price">商品运费：￥{{ item.freight }}</div>
                </div>
              </div>
            </div>
            <div class="allprice">合计：￥{{ item.price }}</div>
          </div>


          <div class="itemcont">
            <div class="text">收货人</div>
            <div class="value">{{ item.name }}</div>
          </div>
          <div class="itemcont">
            <div class="text">收货地区</div>
            <div class="value">{{ item.region_text }}</div>
          </div>
          <div class="itemcont">
            <div class="text">详细地址</div>
            <div class="value">{{ item.address }}</div>
          </div>
          <div class="itemcont">
            <div class="text">支付时间</div>
            <div class="value">{{ item.pay_time }}</div>
          </div>
          <div class="itemcont">
            <div class="text">沙发单号</div>
            <div class="value">
              <div class="txt">{{ item.sofa_logistic_no }}</div><span class="copy"
                :data-clipboard-text="item.logistic_no" @click="copyText()" v-if="item.sofa_logistic_no"><img
                  src="@/assets/img/de_icon6.png" alt=""></span>
            </div>
          </div>
          <div class="itemcont">
            <div class="text">显示器单号</div>
            <div class="value">
              <div class="txt">{{ item.logistic_no }}</div><span class="copy" :data-clipboard-text="item.logistic_no"
                @click="copyText()" v-if="item.logistic_no"><img src="@/assets/img/de_icon6.png" alt=""></span>
            </div>
          </div>

          <div class="valuebtn">
            <button type="button" @click="showMsgClick(0, item.order_no)" v-if="item.status == 6">确定收货</button>
            <button type="button" class="tkbtn" @click="showMsgClick(1, item.advisor_phone)"
              v-if="item.status == 3">退货退款</button>
            <button type="button" v-if="item.status == 1" @click="buyGoOnClick(item.out_order_no)">立即支付</button>
          </div>

        </div>
      </div>
    </van-list>


    <van-popup v-model:show="msgpup" round>
      <div class="magbox">
        <div class="title">{{ msgtitle }}</div>
        <div class="text">{{ msgText }}</div>
        <div class="btns">
          <button type="button" @click="closepup">取消</button>
          <button type="button" @click="okClick">{{ btnText }}</button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import mitts from "@/bus";
import tokenApi from "@/axios/tokenApi";
import Clipboard from 'clipboard'
import { Toast } from "vant";
import { useRouter } from "vue-router";

const router = useRouter();

const userToken = ref("");
const page = ref(1);
const pagedata = ref({});

const list = ref([]);
const loading = ref(false);
const finished = ref(false);

const order_no = ref("")

const outphone = ref("")

const onLoad = () => {
  loading.value = true;
  finished.value = false;
  getInfo()
};

function getInfo() {
  tokenApi({
    url: "/api/orderList",
    method: "post",
    data: {
      userToken: userToken.value,
      page: page.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      pagedata.value = res.data.data.pages

      if (pagedata.value.current_page <= pagedata.value.last_page) {
        list.value = list.value.concat(res.data.data.list)
        page.value = page.value + 1
      }

      loading.value = false;
      finished.value = true;
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

const msgpup = ref(false)
const msgtitle = ref("")
const msgText = ref("")
const btnText = ref("")

function closepup() {
  msgpup.value = false
}

function okClick() {
  if (btnText.value == "确定") {
    // 确认收货

    confirmOrder()
  } else {
    // 客服拨号
    window.location.href = "tel://" + outphone.value;
  }
  msgpup.value = false
}

function showMsgClick(val, item) {
  if (val == 0) {
    order_no.value = item
    msgtitle.value = "是否确认收货"
    msgText.value = "确认收货后钱款将打给商家"
    btnText.value = "确定"
  } else {
    outphone.value = item
    msgtitle.value = "是否确认退款"
    msgText.value = "退款请联系相关顾问经理进行退款"
    btnText.value = "拨号"
  }

  msgpup.value = true
}

function copyText() {
  let clipboard = new Clipboard('.copy')
  clipboard.on('success', () => {
    Toast.success('复制成功');
  })
  clipboard.on('error', () => {
    Toast.fail("复制失败");
  })
}

function confirmOrder() {
  tokenApi({
    url: "/api/activityConfirm",
    method: "post",
    data: {
      userToken: userToken.value,
      order_no: order_no.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      page.value = 1
      list.value = []
      onLoad();
      Toast.success('收货成功');
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

const payData = ref(null)
const totalPrice = ref("")

function buyGoOnClick(out_order_no) {
  tokenApi({
    url: "/api/continuePay",
    method: "post",
    data: {
      userToken: userToken.value,
      out_order_no: out_order_no
    },
  }).then((res) => {

    if (res.data.status == 200) {
      payData.value = res.data.data.jsApiParameters
      totalPrice.value = res.data.data.pay_money

      if (isWeixin()) {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady(res.data.data.order_no), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady(res.data.data.order_no));
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(res.data.data.order_no));
          }
        } else {
          onBridgeReady(res.data.data.order_no);
        }
      }
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function isWeixin() {
  var WxObj = window.navigator.userAgent.toLowerCase();
  if (WxObj.match(/microMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

//支付
function onBridgeReady(orderNo) {
  //挂在window注意
  window.WeixinJSBridge.invoke('getBrandWCPayRequest',
    {
      "appId": payData.value.appId,   //公众号ID，由商户传入
      "timeStamp": payData.value.timeStamp,   //时间戳，自1970年以来的秒数
      "nonceStr": payData.value.nonceStr,      //随机串
      "package": payData.value.package,
      "signType": payData.value.signType,     //微信签名方式：
      "paySign": payData.value.paySign //微信签名
    },
    function (res) {
      console.log(orderNo)
      if (res.err_msg === "get_brand_wcpay_request:ok") {
        //支付成功跳转页面
        // window.location = 'http://www.baidu.com?no=' + orderNo

        router.push({
          name: "payDonePage",
          query: {
            price: totalPrice.value
          }
        });

      } else if (res.err_msg === "get_brand_wcpay_request:fail") {
        //支付失败跳转页面
        // window.location = 'http://www.baidu.com'
      }
    }
  );
}

onMounted(() => {
  userToken.value = window.localStorage.getItem('userToken');
  setTimeout(() => {
    mitts.emit("toptitle", false);
    mitts.emit("closeLoading");
  }, 500);
})

</script>

<style lang="less" scoped>
.orderlist {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}

.listcont {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .item {
    padding: 0 20px 20px 20px;
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 10px #dfdfdf;
    border-radius: 10px;

    .top {
      display: flex;
      align-items: center;
      height: 68px;
      border-bottom: 1px solid #F3F3F3;
      margin-bottom: 20px;

      .text {
        font-size: 24px;
        color: #7D818A;
      }

      .value {
        flex: 1;
        display: flex;
        align-items: center;

        .txt {
          font-size: 24px;
          color: #7D818A;
          margin-right: 10px;
        }

        span {
          width: 32px;
          height: 32px;
          display: block;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }

      .status {
        font-size: 24px;
        font-weight: bold;
        color: #050E20;
      }
    }

    .equipbox {
      margin-bottom: 20px;

      .infobox {
        display: flex;
        margin-bottom: 34px;

        .pic {
          width: 124px;
          height: 124px;
          border: 2px solid #CDD1D6;
          overflow: hidden;
          border-radius: 12px;
          margin-right: 28px;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .cont {
          flex: 1;
          display: flex;
          flex-flow: column;

          .title {
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            font-size: 28px;
            font-weight: 400;
            color: #7D818A;

            span {
              flex: 1;
              min-width: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 32px;
              line-height: 44px;
              font-weight: 400;
              color: #000000;
            }
          }

          .pricetext {
            .price {
              font-size: 28px;
              font-weight: 400;
              color: #7D818A;
              line-height: 32px;
              margin-bottom: 6px;
            }
          }
        }
      }

      .allprice {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        line-height: 76px;
        font-size: 32px;
        border-top: 1px solid #F3F3F3;
        border-bottom: 1px solid #F3F3F3;
      }
    }

    .itemcont {
      display: flex;
      line-height: 40px;
      margin-bottom: 8px;

      .text {
        line-height: 40px;
        font-size: 28px;
        font-weight: 400;
        width: 140px;
        margin-right: 16px;
        color: #050E20;
      }

      .value {
        display: flex;
        color: #7D818A;
        font-size: 28px;

        .txt {
          line-height: 40px;
          font-size: 28px;
          font-weight: 400;
          color: #7D818A;
        }

        span {
          width: 32px;
          height: 32px;
          display: block;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }

    .itemcont:last-of-type {
      margin-bottom: 20px;
    }

    .valuebtn {
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      border-top: 1px solid #F3F3F3;
      padding-top: 16px;

      button {
        width: 144px;
        height: 56px;
        border-radius: 8px;
        border: 1px solid #476FFB;
        font-size: 28px;
        line-height: 56px;
        font-weight: 400;
        color: #476FFB;
        background: #fff;
      }

      .tkbtn {
        color: #FF5F5F;
        border: 1px solid #FF5F5F;
      }
    }
  }
}

.magbox {
  width: 560px;
  padding: 64px 20px 0 20px;
  box-sizing: border-box;

  .title {
    height: 44px;
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
  }

  .text {
    text-align: center;
    margin-bottom: 44px;
    height: 40px;
    font-size: 28px;
    font-weight: 400;
    color: #9599A0;
  }
}

.btns {
  display: flex;
  margin-bottom: 18px;

  button {
    background: none;
    font-size: 28px;
    width: 224px;
    height: 64px;
    line-height: 64px;
    border-radius: 8px;
    margin-right: 32px;
    border: 2px solid #BDBDBD;
    color: #9599A0;
    font-weight: 400;
  }

  button:last-of-type {
    border: 2px solid #476FFB;
    color: #476FFB;
    margin-right: 0;
  }
}
</style>